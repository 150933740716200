/**
 * Utils for generating and parsing app URLs
 */

import type { ParsedUrlQuery } from "node:querystring";
import { useRouter } from "next/router";
import { useState } from "react";
import { stringify as uuidStringify } from "uuid";

import { SUPPORT_EMAIL } from "~/constants.mjs";

/** Browser/Node.js isomorphic implementation */
const toBase64: (bytes: Uint8Array | number[]) => string = (() => {
  if (typeof Buffer !== "undefined") {
    return (bytes) => Buffer.from(bytes).toString("base64");
  }
  return (bytes) => btoa(String.fromCharCode(...bytes));
})();

/** Browser/Node.js isomorphic implementation */
const fromBase64: (base64: string) => Uint8Array | Buffer = (() => {
  if (typeof Buffer !== "undefined") {
    return (base64) => Buffer.from(base64, "base64");
  }
  return (base64) => Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
})();

export const decodeUuid = (slug: string) => {
  if (slug.length !== 22) {
    return undefined;
  }
  const base64 = `${slug.replace(/-/g, "+").replace(/_/g, "/")}==`;
  const bytes = fromBase64(base64);
  return uuidStringify(bytes);
  // TODO validate is UUID
};

export const encodeUUID = (uuid: string) => {
  const bytes: number[] = [...uuid.replaceAll("-", "").matchAll(/.{2}/g)].map(
    (byte) => Number.parseInt(byte.toString(), 16),
  );
  const base64 = toBase64(bytes);
  const slug = base64
    .replace(/\+/g, "-") // Replace + with - (see RFC 4648, sec. 5)
    .replace(/\//g, "_") // Replace / with _ (see RFC 4648, sec. 5)
    .substring(0, 22); // Drop '==' padding
  return slug;
};

const _workspaceList = "/ws";
const _workspacePart = (r: { workspace_id: string }) =>
  `${_workspaceList}/${encodeUUID(r.workspace_id)}`;

export const ROUTES = {
  wsList: _workspaceList,
  wsHome: (r: { workspace_id: string }) => `${_workspacePart(r)}/`,
  playback: (r: { workspace_id: string }) => `${_workspacePart(r)}/playback`,

  // settings
  settings: {
    index: (r: { workspace_id: string }) => ROUTES.settings.employees(r),
    employees: (r: { workspace_id: string }) =>
      `${_workspacePart(r)}/settings/employees`,
    members: (r: { workspace_id: string }) =>
      `${_workspacePart(r)}/settings/members`,
    audit_log: (r: { workspace_id: string }) =>
      `${_workspacePart(r)}/settings/log`,
  },
  download: {
    desktop_client: "/download/desktop_client",
    taylor_desktop_setup: "/api/taylor_desktop_setup.exe",
  },
  email: {
    support: `mailto:${SUPPORT_EMAIL}`,
  },
  auth: {
    signIn: "/auth/signin",
  },
};

export const useUrlParser = () => {
  const router = useRouter();
  return {
    workspace_id: decodeUuid(String(router.query?.workspace_id)),
  };
};

// for server-side props
export function getServerRouteWorkspaceId(
  params: ParsedUrlQuery | undefined,
): string | null {
  if (!params || typeof params.workspace_id !== "string") {
    return null;
  }
  const workspace_id = decodeUuid(String(params.workspace_id));
  if (!workspace_id) {
    return null;
  }
  return workspace_id;
}

export function useQueryParam<T>(
  param_name: string,
  decoder: (formatted: string) => T | null | undefined,
): T | null {
  const router = useRouter();
  const [stabilized] = useState(() => {
    const val = router.query[param_name];
    if (typeof val === "string") {
      const decoded = decoder(val);
      if (decoded) {
        return decoded;
      }
    }
    return null;
  });
  return stabilized;
}
