import {
  ArrowRightOnRectangleIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";
import { Avatar, Dropdown, Navbar as FlowbiteNavbar } from "flowbite-react";
import { signOut, useSession } from "next-auth/react";
import Head from "next/head";
import Link from "next/link";
import type React from "react";

import { usePathname } from "next/navigation";
import { Loading } from "~/components/Loading";
import { WorkspaceRole } from "~/server/db/schema";
import { getInitials, truncateText } from "~/utils/intl";
import { ROUTES, useUrlParser } from "~/utils/route";
import { api } from "~/utils/trpc/next";

export interface AppLayoutProps {
  workspaces?: {
    id: string;
    name: string;
    role: WorkspaceRole;
  }[];
  workspace_id?: string;
  children: React.ReactNode;
}

export function AppLayout(props: AppLayoutProps) {
  return (
    <AppLayoutWithFlushContent {...props}>
      <div className="mx-auto container relative flex-grow py-8 px-4">
        {props.children}
      </div>
    </AppLayoutWithFlushContent>
  );
}

export const AppLayoutWithFlushContent: React.FC<AppLayoutProps> = (props) => {
  const pathname = usePathname();
  const { data: session } = useSession();
  const is_logged_in = !!session?.user?.id;

  const list_workspaces_query = api.workspaces.list.useQuery(void 0, {
    // use props as default value and don't refetch on re-render
    initialData: props.workspaces,
    enabled: !props.workspaces && is_logged_in,
    staleTime: Number.POSITIVE_INFINITY, // never stale, so never revalidate
  });

  if (list_workspaces_query.isError) {
    console.error(list_workspaces_query.error);
  }
  const workspaces = list_workspaces_query.data;
  const { workspace_id } = useUrlParser();
  const workspace = workspaces?.find((ws) => ws.id === workspace_id);
  const links = [] as { href: string; name: string }[];
  if (
    workspace_id &&
    workspace &&
    [WorkspaceRole.Admin, WorkspaceRole.Viewer].includes(workspace.role)
  ) {
    links.push({
      href: ROUTES.playback({ workspace_id }),
      name: "Playback",
    });
  }
  if (
    workspace_id &&
    workspace &&
    [WorkspaceRole.Admin, WorkspaceRole.ITSupport].includes(workspace.role)
  ) {
    links.push({
      href: ROUTES.settings.index({ workspace_id }),
      name: "Settings",
    });
  }

  return (
    <div className="flex flex-col h-screen">
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b36534" />
        <meta name="msapplication-TileColor" content="#fef6e7" />
        <meta name="theme-color" content="#fef6e7" />
      </Head>
      <FlowbiteNavbar
        fluid={true}
        className="select-none border-b"
        theme={{
          base: "bg-white py-2.5 dark:border-gray-700 dark:bg-gray-800",
          inner: {
            base: "mx-auto flex flex-wrap items-center justify-between px-4",
          },
        }}
      >
        <FlowbiteNavbar.Brand href={ROUTES.wsList}>
          <img
            src="/favicon.ico"
            className="mr-3 "
            alt="Taylor Logo"
            width={24}
            height={24}
          />
          <span className="self-center whitespace-nowrap text-xl font-semibold text-yellow-950 pr-8">
            Taylor
          </span>
        </FlowbiteNavbar.Brand>
        <FlowbiteNavbar.Collapse>
          {links.map((link) => (
            <FlowbiteNavbar.Link
              key={link.href}
              href={link.href}
              active={pathname === link.href}
              as={Link}
            >
              {link.name}
            </FlowbiteNavbar.Link>
          ))}
        </FlowbiteNavbar.Collapse>
        <div className="flex flex-1" />
        {is_logged_in && (
          <FlowbiteNavbar.Collapse
            theme={{
              list: "mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium md:items-center",
            }}
          >
            <Dropdown
              arrowIcon={true}
              inline
              className="max-w-xs"
              label={
                <Avatar
                  alt="User profile"
                  img={session?.user?.image ?? ""}
                  placeholderInitials={getInitials(
                    session?.user?.name ?? session?.user?.email ?? "",
                  )}
                  rounded
                  size="sm"
                  className="p-2 md:p-0"
                />
              }
            >
              <Dropdown.Header>
                <div className="text-md font-medium">{session?.user?.name}</div>
                <div className="block truncate text-sm font-light">
                  {session?.user?.email}
                </div>
                {session?.user?.provider ? (
                  <div className="block truncate text-sm font-light">
                    Logged in with {session?.user?.provider}
                  </div>
                ) : null}
              </Dropdown.Header>

              {links.length > 0 ? <Dropdown.Divider /> : null}
              <Dropdown.Item
                href={ROUTES.wsList}
                icon={() => <Squares2X2Icon className="h-4 w-4 mr-2" />}
              >
                My Workspaces
              </Dropdown.Item>

              {list_workspaces_query.isLoading ? (
                <Dropdown.Header>
                  <Loading />
                </Dropdown.Header>
              ) : null}
              {list_workspaces_query.isError ? (
                <Dropdown.Header>An error occurred.</Dropdown.Header>
              ) : null}
              {workspaces?.map((ws) => (
                <Dropdown.Item
                  key={ws.id}
                  href={ROUTES.wsHome({
                    workspace_id: ws.id,
                  })}
                  className={`${
                    ws.id === workspace_id ? "font-semibold text-gray-500" : ""
                  } text-ellipsis truncate`}
                >
                  <span className="ml-6">{truncateText(ws.name, 30)}</span>
                </Dropdown.Item>
              ))}
              {workspaces?.length === 0 ? (
                <Dropdown.Header>
                  You don&apos;t have any workspaces yet.
                </Dropdown.Header>
              ) : null}
              <Dropdown.Divider />
              <Dropdown.Item
                icon={() => (
                  <ArrowRightOnRectangleIcon className="h-4 w-4 mr-2" />
                )}
                onClick={() => void signOut()}
              >
                Sign out
              </Dropdown.Item>
            </Dropdown>
          </FlowbiteNavbar.Collapse>
        )}
        <FlowbiteNavbar.Toggle className="p-2 ml-auto" />
      </FlowbiteNavbar>

      <div className="flex-fit flex flex-grow flex-row overflow-y-auto">
        {props.children}
      </div>
    </div>
  );
};
