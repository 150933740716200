import type React from "react";

import { PulseMultiple } from "./PulseMultiple";

export const Loading: React.FC = () => {
  return (
    <div className="flex-grow flex flex-col items-center justify-center h-full">
      <PulseMultiple width={48} height={48} />
      <div style={{ color: "gray", fontWeight: "500" }}>Loading...</div>
    </div>
  );
};
