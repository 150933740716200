import "~/styles/globals.css";

import * as Sentry from "@sentry/nextjs";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppType } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { AppLayout } from "~/components/AppLayout";
import { env } from "~/env.mjs";
import type { WorkspaceRole } from "~/server/db/schema";
import { api } from "~/utils/trpc/next";

if (typeof window !== "undefined" && env.NEXT_PUBLIC_ENV === "production") {
  // checks that we are client-side
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    loaded: (posthog) => {
      if (env.NEXT_PUBLIC_NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
  });
}

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-sans",
});

function FontFixer({ children }: { children: React.ReactNode }) {
  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      {children}
    </>
  );
}

export interface PageLayoutParams {
  layout?: {
    component?: React.ComponentType;
  };
}

export interface PageProps {
  session: Session | null;
  workspaces?: { id: string; name: string; role: WorkspaceRole }[];
  workspace_id?: string;
}

const TaylorApp: AppType<PageProps> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  useEffect(() => {
    if (session?.user) {
      const user = session.user;
      posthog.identify(user?.id, {
        name: user?.name,
        email: user?.email,
        image: user?.image,
        provider: user.provider,
        type: "web_user",
      });
      Sentry.setUser({
        id: user.id,
        username: user.name ?? undefined,
        email: user.email ?? undefined,
        image: user?.image,
        provider: user.provider,
        type: "web_user",
      });
    }
  }, [session?.user]);
  const router = useRouter();

  // by default, next.js does *not* blow away the page component when the route changes.
  // This is an insane default, as state may easily be carried around between pages. I
  // don't understand how they fucked this up so badly. I'm torn on whether to make this
  // opt-in per-page, as I recognize we're outside next.js norms here. However I'm way
  // more likely to get bit forgetting to opt-in than accidentally being defaulted-out.
  // If I ever want to opt-out, then I'll make it configurable.
  const page_key = router.asPath;

  const Layout = (Component as PageLayoutParams).layout?.component ?? AppLayout;

  return (
    <>
      <Head>
        <title>Taylor</title>
      </Head>
      <FontFixer>
        <PostHogProvider client={posthog}>
          <SessionProvider session={session} refetchOnWindowFocus={false}>
            <Layout
              workspaces={pageProps.workspaces}
              workspace_id={pageProps.workspace_id}
            >
              <Component key={page_key} {...pageProps} />
            </Layout>
          </SessionProvider>
        </PostHogProvider>
      </FontFixer>
    </>
  );
};

export default api.withTRPC(TaylorApp);
