export const WEBSITE_DOMAIN = "taylor.timeetudes.com";

/** @deprecated Use workspace roles instead */
export const SUPER_ADMIN_EMAIL_ALLOWLIST = ["roger@timeetudes.com"];

export const SEED_EMAILS_LIST = [
  ...SUPER_ADMIN_EMAIL_ALLOWLIST,

  "roger@zura.wiki",

  // World Travel, Inc.
  "lsinclair@worldtravelinc.com", // Lieza Sinclair
  "dphelps@worldtravelinc.com", // Daniele Phelps
  "lhalout@worldtravelinc.com", // Lisa Halout
  "tmurgas@worldtravelinc.com", // Tracy Murgas
  "pzager@worldtravelinc.com", // Pam Zager
];

export const EMAIL_DOMAIN_ALLOWLIST = [
  // Time Etudes
  "timeetudes.com",
  // Roger's personal email
  "zura.wiki",
  // World Travel, Inc.
  "worldtravelinc.com",
];

// 90 minutes, in milliseconds
export const max_video_length_ms = 10 * 60_000;

export const N_PARTS_TO_CREATE = 5;

export const seed_workspace_hardcoded_id =
  "36e6674f-20e0-41e9-86b5-063c343e1d44";

// We should never use this workspace id, it's to test that we can't access workspaces we don't have access to
export const top_secret_workspace_hardcoded_id =
  "6225e66f-9185-4815-ad78-8bc7a4f7334c";

export const smoke_test_workspace_hardcoded_id =
  "e6faa2c7-619e-46de-bb9d-41a04eb25fe8";

export const smoke_test_uploader_user_hardcoded_id =
  "75a28f38-919f-4c8f-85fe-050b34fb9348";
export const smoke_test_uploader_user_hardcoded_token =
  "e1cab7af-cfcc-4cfa-b665-9cbfaa80a448";

// feature flag off features used for self-serve plg, like CRUDing workspaces and users
export const self_serve_plg_workspaces = false;

// Uploader configuration
export const screen_recording_data_pull_frequency_ms = 15_000;
export const VIDEO_ROTATION_INTERVAL_MS = max_video_length_ms;

export const SENTRY_DSN =
  "https://283a54cb08fc06fdd5aa982ba7302003@o4507381042511872.ingest.us.sentry.io/4507381043822593";

export const SUPPORT_EMAIL = "hi@timeetudes.com";
