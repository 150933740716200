import { createTRPCNext } from "@trpc/next";

import type { AppRouter } from "~/server/api/root";
import { defaultConfig } from "~/utils/trpc/common";

/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      ...defaultConfig,

      abortOnUnmount: true,

      // configuration to prevent unexpected default behavior of refetching queries every time
      // https://tanstack.com/query/latest/docs/react/guides/important-defaults
      // window focuses or reconnects
      // https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            staleTime: 0,
            retry: false,
            networkMode: "always", // default is don't fetch if not "online"
          },
          mutations: {
            networkMode: "always", // default is don't fetch if not "online"
          },
        },
      },
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});
