export const longDateFormatter = Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "long",
});
export const shortDateFormatter = Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
});
export const monthYearFormatter = Intl.DateTimeFormat("en-US", {
  month: "long",
  year: "numeric",
});

export const formatBytes = (bytes: number): string => {
  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let decimal = 0;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = 1024 ** (i + 1);

    if (bytes <= -decimal || bytes >= decimal) {
      return `${+(bytes / decimal).toPrecision(3)} ${units[i]!}`;
    }
  }

  return `${bytes} B`;
};

export function getInitials(fullName: string): string {
  const names = fullName.split(" ");
  if (names.length === 1) {
    return fullName.slice(0, 1).toUpperCase();
  }
  // get first and last initials
  return names
    .slice(0, 2)
    .map((n) => n.slice(0, 1))
    .join("")
    .toUpperCase();
}

export function truncateText(s: string, maxLength = 50): string {
  if (s.length > maxLength) {
    return `${s.slice(0, maxLength - 1)}…`;
  }
  return s;
}
